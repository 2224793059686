import React, { useEffect, useRef, useState } from 'react';
import { fetchRapportFinancier, rapportfinancierSelector, fetchBandeControle, setFinBandeControle } from './rapportfinancierSlice';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button';
import styles from './RapportFinancier.module.css';
import { Rapport } from '../rapport/Rapport';
import { useReactToPrint } from 'react-to-print';
import { useSelector, useDispatch } from 'react-redux';
import Container from 'react-bootstrap/Container'
import format from "date-fns/format";
import fr from 'date-fns/locale/fr';
import Spinner from 'react-bootstrap/Spinner';

import {
  Redirect,
  useHistory,
  useParams,
  withRouter
} from "react-router-dom";

export const RapportFinancier = () => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const history = useHistory();
  const dispatch = useDispatch()
  const service = useSelector(state => state.service.service)
  const cumul = useSelector(state => state.cumul.cumul)
  const rapports = useSelector(state => state.rapports)
  const rapport = useSelector(state => state.rapport.rapport)
  const {rapportfinancier, loading, hasErrors, loaded, donnee, hasMore, finBandeControle} = useSelector(rapportfinancierSelector)
  const [hasLess, sethasLess] = useState(true);
  const [pause, setPause] = useState(false);
  let timer;

  useEffect(() => {

    dispatch(fetchRapportFinancier(service[0]))
    
  }, [dispatch])

  useEffect(() => {
    if(rapport.rapport_id == 5) {
      if (rapportfinancier.sections.length >= rapportfinancier.taille) {
        dispatch(setFinBandeControle())
        clearInterval(timer);
        return;
      } else {
        if (hasLess) {
          timer = setInterval(() => {
            dispatch(fetchBandeControle(service[0]))
          }, 5000);  
        }
      }
        return () => clearInterval(timer);
    }
  }, [hasLess, rapportfinancier])

  const fonctionPause = () => {
    clearInterval(timer);
    sethasLess(false)
    setPause(true)
    return;
  }

  const fonctionReprise = () => {
    sethasLess(true)
    setPause(false)
  }

  const renderHeader = (ligne) => {
    if(ligne.ligne_type == 'titre') {
      return (ligne.ligne_donnee.map((donnee) =>
        <th>{donnee}</th>
      ))
    }
  }

  const renderBody = (ligne) => {

    /* Si c'est le rapport  */
    if(rapportfinancier.rapport_id == 4) {

      if(ligne.ligne_type == 'valeur') {

        return ligne.ligne_donnee.map(function(donnee, index) {
          
          if(donnee == 'Total famille') {
            return <td id={index} colSpan="2" className={styles.total}>{donnee}</td>
          } else {
            return <td id={index}>{donnee}</td>
          }

        })

      }

    } else if (rapportfinancier.rapport_id == 2 || rapportfinancier.rapport_id == 3) {

      if(ligne.ligne_type == 'valeur') {

        return ligne.ligne_donnee.map(function(donnee, index) {
          
          if(donnee == 'Total annulations' || donnee == 'Total offerts') {
            return <td id={index} colSpan="4" className={styles.total}>{donnee}</td>
          } else {
            return <td id={index}>{donnee}</td>
          }

        })

      }
      
    } else {

      if(ligne.ligne_type == 'valeur') {

        return (ligne.ligne_donnee.map((donnee, index) =>
          <td id={index}>{donnee}</td>
        ))

      }

    }

  }

  const renderTitle = () => {
    
    if (rapports.cumul == true) {

      return <h2><b>{rapportfinancier.rapport_nom}</b> <b>du</b> {cumul.date_min_affichage} <b>au</b> {cumul.date_max_affichage}</h2>  
       
    } else {

      const date = format(Date.parse(service[0].date_service), 'EEEE dd MMMM yyyy', {locale: fr})
      
      return <h2><b>{rapportfinancier.rapport_nom}</b> Service n°{service[0].num_service} <b>du</b> {date}</h2>   
    }
    
  }

  const renderNumtabres = () => {
    
    if (rapports.cumul == false && rapport.rapport_id == 5) {

      return <h2>BANDE CONTRÔLE N°{service[0].num_tab_res_affichage}</h2>  
       
    } 
    
  }

  const renderChargement = () => {
    if(hasMore == true && hasLess == true && pause == false) {
      return (
        <div className={styles.spinner}>
          <Spinner className={styles.spinner} variant="dark" animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )
    } 
  }

  const renderRapportFinancier = () => {
    
    /* Rapport des ventes */
    if(rapportfinancier.rapport_id == 4)  {

      return (
        <div ref={componentRef} >
          <div className={styles.grille_titre}>
            <div>{renderTitle()}</div>
            <div className={styles.numtabres} >{renderNumtabres()}</div>
            <Button className={styles.bouton_imprimer} variant="secondary" onClick={handlePrint}>Imprimer</Button>
          </div>
            {
              rapportfinancier.sections.map((section) => 
                <div className={styles.section_titre}>
                  <h3>{section.section_nom}</h3>
                  <div className={styles.grille_tableau_vente}>
                    {section.tableaux.map((tableau) => 
                    <div className={styles.tableau}>
                      <table>
                        <caption>{tableau.tableau_nom}</caption>
                        <thead>
                        {tableau.lignes.map((ligne) =>
                          <tr>{renderHeader(ligne)}</tr>
                        )}
                        </thead>
                        <tbody>
                        {tableau.lignes.map((ligne) =>
                          <tr>
                            {renderBody(ligne)}
                          </tr>
                        )}
                        </tbody>
                      </table>
                    </div>)}
                  </div>
                </div>
              )
            }
          </div>
      )

    } else {

      return (
        <div ref={componentRef} >
          <div className={styles.grille_titre}>
            <div>{renderTitle()}</div>
            <div className={styles.numtabres} >{renderNumtabres()}</div>
            <Button className={styles.bouton_imprimer} variant="secondary" onClick={handlePrint}>Imprimer</Button>
          </div>
            {
              rapportfinancier.sections.map((section) => 
                <div className={styles.section_titre}>
                  <h3>{section.section_nom}</h3>
                  <div className={styles.grille_tableau}>
                    {section.tableaux.map((tableau) => 
                    <div className={styles.tableau}>
                      <table>
                        <caption>{tableau.tableau_nom}</caption>
                        <thead>
                        {tableau.lignes.map((ligne) =>
                          <tr>{renderHeader(ligne)}</tr>
                        )}
                        </thead>
                        <tbody>
                        {tableau.lignes.map((ligne) =>
                          <tr>
                            {renderBody(ligne)}
                          </tr>
                        )}
                        </tbody>
                      </table>
                    </div>)}
                  </div>
                </div>
              )
            }
          </div>
      )

    }

  }

  const renderBandeControleList = () => {
    
    if (rapports.cumul == true) { 

      return (
          <div className={styles.bande} >
            
          {
            rapportfinancier.sections.map((section, index) => 
              <div key={index} className={styles.document}>
                {
                  section.entete.map((entete) =>
                    
                    <div>{entete}</div>      
                    
                  )
                }
                <div className={styles.document_entete}>
                  {
                    section.commande.map((commande) =>
                    
                      <div className={styles.document_commande}>
                        {commande.map((element) => 
                          <div>{element}</div>
                        )}
                      </div> 
                    
                    )
                  }
                </div>
                <div className={styles.document_entete}>
                  {
                    section.totaux.map((totaux) =>
                    
                      <div className={styles.document_totaux}>
                        {totaux.map((element) => 
                          <div>{element}</div>
                        )}
                      </div>      
                  
                    )
                  }
                </div>
                <div className={styles.document_entete}>
                  {
                    section.reglement.map((reglement) =>
                    
                      <div className={styles.document_totaux}>
                        {reglement.map((element) => 
                          <div>{element}</div>
                        )}
                      </div> 
                  
                    )
                  }
                </div>
              </div>)
          }  
        </div>
        )

    } else {

      return (
        
        <div  className={styles.bande}>
        {
          rapportfinancier.sections.map((section) => 
            <div className={styles.document}>
              {
              section.entete.map((entete) =>
                
                <div>{entete}</div>      
                
              )
              }
              <div className={styles.document_entete}>
                {
                  section.commande.map((commande) =>
                  
                    <div className={styles.document_commande}>
                      {commande.map((element) => 
                        <div>{element}</div>
                      )}
                    </div>      
                  
                  )
                }
              </div>
              <div className={styles.document_entete}>
                {
                  section.totaux.map((totaux) =>
                  
                  <div className={styles.document_totaux}>
                    {totaux.map((element) => 
                      <div>{element}</div>
                    )}
                  </div>      
                
                  )
                }
              </div>
              <div className={styles.document_entete}>
                {
                  section.reglement.map((reglement) =>
                  
                  <div className={styles.document_totaux}>
                    {reglement.map((element) => 
                      <div>{element}</div>
                    )}
                  </div>      
                
                  )
                }
              </div>
            </div>)
        }
      </div>)
    }
  }

  const renderEtatChargement = () => {
    if(hasMore == true && finBandeControle == false && pause == false) 
      return <Button className={styles.bouton_etat} variant="secondary" onClick={fonctionPause}>Pause</Button>
    else if(pause == true && finBandeControle == false)
      return <Button className={styles.bouton_etat} variant="secondary" onClick={fonctionReprise}>Reprise</Button>
  }

  const renderBandeControle = () => {
    return (
      <div ref={componentRef} >
        <div className={styles.grille_titre}>
          <div>{renderTitle()}</div>
          {renderChargement()}
          {renderEtatChargement()}
          <Button className={styles.bouton_imprimer} variant="secondary" onClick={handlePrint}>Imprimer</Button>
        </div>
        {renderBandeControleList()}
      </div>
    )
  }

  const renderRapport = () => {

    if (loading) return <Alert variant='secondary'> Chargement du rapport </Alert>
    if (hasErrors) return <Alert variant='danger'> Impossible de charger le rapport </Alert>
    if (donnee == false) return <Alert variant='warning'> Aucune donnée dans la période sélectionnée </Alert>

    if (loaded == true) {

      if(rapportfinancier.rapport_id == 5) 
        return renderBandeControle()
      else
        return renderRapportFinancier()

    }

  }

  const renderRapportList = () => {
    let side = true
    return rapports.rapports.map((rapport) => <Rapport key={rapport.rapport_id} rapport={rapport} side={side} /> )
  }

  return (
    <div className={styles.rapport_wrapper}>
      <div className={styles.div_rapport_list}>
        <div className={styles.rapport_list}>
          {renderRapportList()}
        </div>
      </div>
      <div className={styles.div_rapport}>
        {renderRapport()}
      </div>
    </div>
  )

}
